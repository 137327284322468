import React, {useRef, useEffect, useState, createRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetHelperFunction } from '../store/schemas/helperFunctionsSlice';
import { LoadingOverlay } from '@mantine/core';

const GlobalLoadingOverlay = () => {
  const thunkLoading = useSelector(state => state.mainapp.helperFunctions.thunkLoading)
 

  return (
    <>
        <LoadingOverlay style={{position:'fixed', zIndex:"9999999", top:0, left:0}}  visible={thunkLoading.visible} loaderProps={{color:'red'}} overlayBlur={2} overlayColor="theme.colors.dark[5]" />
    </>
  )

}

export default GlobalLoadingOverlay;